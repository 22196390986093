$(document).ready(function () {
  // Redirect the URL for new car reviews filters
  $('#make').on('change', function () {
    var url = $(this).val().replace(/\s+/g, '');
    window.location.href = '/new-cars/by-make/' + url;
  });
  $('#year').on('change', function () {
    window.location.href = '/new-cars/' + $(this).val();
  });
  var menu = new Menu(); // Actions

  inputCheck('.sidebar-search-button', '.form-text'); // Multi Reply Fix

  var submitCount = 0;
  jQuery('.forum').on('submit', '#reply', function (e) {
    if (submitCount > 1) {
      e.preventDefault();
    }
  });
  jQuery('.forum').on('click', '#reply #submit', function (e) {
    if (submitCount > 1) {
      jQuery(this).attr('disabled', 'disabled');
    }

    submitCount++;
  });
  $(window).on('resize', function (e) {
    $('#cycle').css('height', calcHeight());
  });
  /**
   * @name inputCheck
   * @description Unknown
   * @param {type} selector Description
   * @param {type} field Description
   */

  function inputCheck(selector, field) {
    $(selector).on('click', function (event) {
      if ($(field).val() == '') {
        warning(field);
        event.preventDefault();
        $('.form-text').attr('placeholder', 'Please enter some text.');
      }
    });
    $(field).on('change', function (event) {
      $(field).removeClass('warning');
    });
  }
  /**
   * @name warning
   * @description Unknown
   * @param {type} input Description
   */


  function warning(input) {
    $(input).addClass('warning');
  }

  var clicked = false;
  $('#mobile-specs .button').on('click', function () {
    if (clicked) {
      $('.specs-container').slideUp(500);
      $('#mobile-specs .button').html('Expand');
      clicked = false;
    } else {
      $('.specs-container').slideDown(500);
      $('#mobile-specs .button').html('Close');
      clicked = true;
    }

    return false;
  });
  $('a.parsedYouTube').each(function () {
    var link = $(this);
    var src = link.attr('href');

    if (/^http(s|):\/\/(www.|)youtube.com\/embed\//.test(src)) {
      link.after($('<div>', {
        class: 'youtube-wrapper'
      }).append($('<iframe>', {
        class: 'youtube',
        src: src
      })));
    }

    link.remove();
  });
  $('a.parsedFacebook').each(function () {
    var link = $(this);
    var src = link.attr('href');

    if (/^http(s|):\/\/(www.|)facebook.com\/plugins\//.test(src)) {
      link.after($('<div>', {
        class: 'facebook-wrapper'
      }).append($('<iframe>', {
        class: 'youtube',
        src: src
      })));
    }

    link.remove();
  });
});
/**
 * @name calcHeight
 * @description description
 * @return {type} Description
 */

function calcHeight() {
  if ($('.overlay-text').css('position') == 'relative') {
    var overlay = $('.currentSlide .overlay-text').innerHeight();
    return $('#space img').height() + $('.currentSlide .text').innerHeight() + overlay;
  } else {
    return $('#space img').height() + $('.currentSlide .text').innerHeight();
  }
} // Plugin


jQuery.fn.makeModal = function (options) {
  // Default Settings (Imported Options Override)
  var settings = jQuery.extend({
    headline: '',
    content: '',
    cta: '',
    htmlContent: null,
    theme: 'default',
    type: 'click',
    location: '#main'
  }, options);
  var mm = new makeModal(jQuery(this), settings);
  return;
};

var makeModal = function (root, settings) {
  'use strict';

  var global = {
    root: root,
    headline: settings.headline,
    content: settings.content,
    cta: settings.cta,
    htmlContent: settings.htmlContent,
    theme: settings.theme,
    type: settings.type,
    modal: {
      markup: '<div id="modal-overlay"></div><div class="modal" id="modal-window"></div>',
      inner: '<div class="modal-inner-wrapper"><div class="modal-inner"></div></div>',
      id: '#modal-window',
      overlay: '#modal-overlay',
      location: settings.location
    }
  };

  var init = function init() {
    // Generate Modal Window
    if (!jQuery(global.modal.id).length) {
      make();
    } // What Type?


    switch (global.type) {
      case 'click':
        root.on('click', function () {
          toggle();
        });
        break;

      case 'onload':
        jQuery(window).on('load', function () {
          toggle();
        });
        break;
    }

    jQuery(window).on('resize', function () {
      position();
    });
  };

  var make = function make() {
    //Create the Modal Structure if it doesn't exist.
    jQuery(global.modal.location).append(global.modal.markup);
    var modal = jQuery(global.modal.id);
    var overlay = jQuery(global.modal.overlay);
    modal.find('.close, .cta, #modal-overlay').live('click', function (e) {
      e.preventDefault();
      modal.removeClass('visible');
      overlay.removeClass('visible');
    });
  }; // Show and Hide the Modal Window. Populate the contents.


  var toggle = function toggle() {
    var modal = jQuery(global.modal.id);
    var overlay = jQuery(global.modal.overlay); //Reset the HTML

    jQuery(global.modal.id).html('');
    jQuery(global.modal.id).wrapInner(global.modal.inner);
    var wrapper = modal.find('.modal-inner-wrapper').addClass(global.theme);
    var inner = modal.find('.modal-inner'); //Determines what content to pull. Either from the Plugin or the Page.

    if (global.htmlContent == null) {
      inner.html(global.content);
    } else {
      inner.html(jQuery(global.htmlContent).html());
    } //Headline and CTA


    wrapper.prepend(global.headline);
    wrapper.append(global.cta); //Add Close Button

    inner.prepend('<a class="close" href="#">close</a>'); //Toggle Visibility

    modal.toggleClass('visible');
    overlay.toggleClass('visible');
    position(); //Recalculate Position after toggle.
  };

  var position = function position() {
    var modal = jQuery('#modal-window');
    var windowHeight = jQuery(window).height();
    var modalHeight = modal.find('.modal-inner-wrapper').outerHeight();
    var position = windowHeight / 2 - modalHeight / 2; // Modal is bigger than window, position absolute applied to allow
    // scrolling with oversized modal.

    if (position <= 0) {
      position = 0;
      modal.css('position', 'absolute');
    } else {
      modal.css('position', 'fixed');
    }

    jQuery('#modal-window').css('top', position + 'px');
  };

  init();
}; // Equalize Heights


jQuery.fn.equalizeHeights = function (options) {
  var settings = jQuery.extend({
    row: 'views-row',
    inner: 'equalizeInner',
    correction: 0
  }, options);
  var inner = settings.inner;
  var adjust = settings.correction;
  var rowName = settings.row;
  var flag = false;
  var ticking = false;
  var rows = jQuery(this).find('.' + rowName);
  rows.wrapInner('<div class="' + inner + '">'); // Setup

  equalizeHeights();
  /**
   * @name update
   * @description description
   */

  function update() {
    equalizeHeights();
    ticking = false;
  }
  /**
   * @name requestTick
   * @description description
   */


  function requestTick() {
    if (ticking) {
      requestAnimationFrame(function () {
        update();
      });
    }

    ticking = true;
  }

  if (settings.inner != '') {
    flag = true;
  }

  jQuery(window).on('load', function () {
    equalizeHeights();
  });
  jQuery(window).on('resize', function () {
    requestTick();
  }); // iOS Orientation Fix

  window.addEventListener('orientationchange', equalizeHeights);
  /**
   * @name equalizeHeights
   * @description description
   */

  function equalizeHeights() {
    var maxHeight = 0;
    rows.each(function () {
      if (flag) {
        row = jQuery(this).find('.' + inner);
      } else {
        row = jQuery(this);
      }

      if (row.outerHeight() > maxHeight) {
        maxHeight = row.outerHeight() + adjust;
      }
    });
    rows.height(maxHeight);
  }
};